import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { RepresentativeStatus, STREAMING_ROOM } from 'common.interfaces';
import { finalize, map, Observable, of, switchMap, tap } from 'rxjs';
import { UserService } from '../login/providers';
import { isPlatformServer } from '@angular/common';
import { LiveUpdateService } from '../live-update';
import { convertStatusName } from './live-status.utils';

@Injectable({
  providedIn: 'root',
})
export class LiveStatusService {
  readonly liveUpdateService = inject(LiveUpdateService);
  readonly userService = inject(UserService);
  readonly platformId = inject(PLATFORM_ID);

  getAdvocatesLiveStatus(): Observable<RepresentativeStatus[]> {
    if (isPlatformServer(this.platformId)) {
      return of([]);
    }
    let disconnectFn: () => void;
    const obs = new Observable<Array<RepresentativeStatus & { status_name: string }>>((observer) => {
      this.liveUpdateService
        .addLiveSubscription({
          room: STREAMING_ROOM.ADVOCATE_STATUS,
          onEvent: (evt) => {
            observer.next(evt);
          },
        })
        .pipe(
          tap((disconnectLiveUpdateFn) => {
            disconnectFn = disconnectLiveUpdateFn;
          }),
        )
        .subscribe();
    });
    return obs.pipe(
      map((liveStatuses: Array<RepresentativeStatus & { status_name: string }>) => {
        return convertStatusName(liveStatuses);
      }),
      finalize(() => disconnectFn()),
    );
  }

  getAdvocateLiveStatus(userTalkdeskIdOrName: string): Observable<RepresentativeStatus | undefined> {
    return this.getAdvocatesLiveStatus().pipe(
      map((status) => {
        return status.find((s) => {
          return s.user_id === userTalkdeskIdOrName || s.name === userTalkdeskIdOrName;
        });
      }),
    );
  }

  getCurrentUserLiveStatus(): Observable<RepresentativeStatus | undefined> {
    return this.userService.getSelfOrImpersonatedUser$().pipe(
      map((u) => u?.talkdeskUserId ?? u?.name ?? ''),
      switchMap((userTalkdeskIdOrName) => this.getAdvocateLiveStatus(userTalkdeskIdOrName)),
    );
  }
}
